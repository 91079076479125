import Store from '@/store'
import { Dialog } from 'vant'
import { isPcWechat, getErrorMsg, getStorage, setDocumentTitle } from '@/utils'
import { DEFAULT_CHANNEL, ROUTE_NOT_INTERCEPT_CODE } from '@/config'

export default router => {
  router.beforeEach(async (to, from, next) => {
    try {
      // pc微信弹窗提示
      if (isPcWechat()) {
        Dialog.alert({
          title: '提示',
          message: '请使用手机微信打开',
          showConfirmButton: false
        })
        return
      }

      // 防止设置标题重复请求
      if (window.location.href.indexOf('replace_title') !== -1) return

      // 不需要微信授权的页面直接进入
      if (to.meta.notAuth) {
        return next()
      }

      // 换取token  如果有goWxLogin 说明已经授权登录回来了
      if (getStorage('goWxLogin')) {
        await Store.dispatch('user/getToken')
      }

      // 不存在用户信息，请求用户信息
      if (!Store.state.user.userInfo) {
        console.log('不存在用户信息，请求用户信息')
        await Store.dispatch('user/getUserInfo')
      }

      // 不存在微信配置，请求微信配置
      if (!Store.state.user.wxConfig) {
        await Store.dispatch('user/getWxConfig')
      }

      if (!to.query.channel) {
        const channel = from.query.channel || DEFAULT_CHANNEL
        to.query.channel = channel
        next({ ...to, replace: true })
        return
      }

      // 设置标题
      const title = to.meta.title || '微淼'
      setDocumentTitle(title)

      // LOG:访问记录埋点
      Store.$log.show({
        pre_page: from.fullPath,
        current_page: to.fullPath,
        loc_id: to.query.channel,
        module_part: '107_current_page'
      })

      next()
    } catch (error) {
      const { code } = error
      console.log(code, 'beforeEach', error)
      // 微信授权情况不属于页面白屏
      if (code && ROUTE_NOT_INTERCEPT_CODE.includes(code)) return

      const errorMsg = getErrorMsg(error)
      Dialog.alert({
        title: '提示',
        message: '页面进入失败',
        showConfirmButton: false
      })
      Store.$log.show({
        pre_page: from.fullPath,
        current_page: window.location.href,
        module_part: '109_dkzbj_h5_error_00001',
        module_ori: '【页面加载失败】',
        alternate: JSON.stringify({
          pagename: '大v直播-页面异常',
          error,
          errorMsg
        })
      })
    }
  })
}
