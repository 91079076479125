import Store from '@/store'
import { APP_ID, BUSINESS_NAME } from '@/config'
import { LIVE_PLAYER_EVENTS } from './types'
import { getLiveLogInfo } from './index'
import { WmPlayerEvents } from '@wm/wm-live-sdk'
import { mergeLogAlternate } from '@/helper/log'
import { ClassroomMessageControllerCommands } from '@wm/wm-live-components'
import pkg from '../../../package.json'

/**
 * @constant 直播播放器事件埋点
 */
export const LIVE_PLAYER_EVENT_LOG = {
  [LIVE_PLAYER_EVENTS.PLAY]: {
    module_ori: '直播间-直播中-播放',
    module_part: '109_dkzbj_h5_zbj_00028'
  },
  [LIVE_PLAYER_EVENTS.PAUSE]: {
    module_ori: '直播间-直播中-暂停',
    module_part: '109_dkzbj_h5_zbj_00029'
  },
  [LIVE_PLAYER_EVENTS.FULLSCREEN]: {
    module_ori: '直播间-直播中-全屏',
    module_part: '109_dkzbj_h5_zbj_00030'
  },
  [LIVE_PLAYER_EVENTS.QUITFULLSCREEN]: {
    module_ori: '直播间-直播中-取消全屏',
    module_part: '109_dkzbj_h5_zbj_00033'
  }
}

/**
 * @constant 点播播放器事件埋点
 */
export const PLAYBACK_PLAYER_EVENT_LOG = {
  [WmPlayerEvents.PLAY]: {
    module_ori: '直播间-回放-播放',
    module_part: '109_dkzbj_h5_zbj_00020'
  },
  [WmPlayerEvents.PAUSE]: {
    module_ori: '直播间-回放-暂停',
    module_part: '109_dkzbj_h5_zbj_00021'
  }
}

/**
 * @constant: 切换聊天类型埋点
 */
export const MSG_TYPE_LOG = {
  all: {
    module_ori: '底部功能条-点击【全部消息ICON】',
    module_part: '109_dkzbj_h5_zbj_00034'
  },
  teacher: {
    module_ori: '底部功能条-点击【只看老师ICON】',
    module_part: '109_dkzbj_h5_zbj_00035'
  }
}

/**
 * @constant 消息控制区埋点
 */
export const MESSAGE_CONSTOLLER_LOG = {
  [ClassroomMessageControllerCommands.INPUT_ENTER]: {
    module_ori: '点击键盘上的【发送】按钮',
    module_part: '109_dkzbj_h5_zbj_00040'
  },
  [ClassroomMessageControllerCommands.INPUT_CLICK]: {
    module_ori: '底部功能条-点击【文本框】',
    module_part: '109_dkzbj_h5_zbj_00036'
  },
  [ClassroomMessageControllerCommands.SEND_BTN_CLICK]: {
    module_ori: '点击【发送】按钮',
    module_part: '109_dkzbj_h5_zbj_00038'
  },
  [ClassroomMessageControllerCommands.EMOJI_ICON_CLICK]: {
    module_ori: '底部功能条-点击【表情包ICON】',
    module_part: '109_dkzbj_h5_zbj_00041'
  },
  [ClassroomMessageControllerCommands.EMOJI_PANEL_VISIBLE]: {
    event: 'show',
    module_ori: '表情列表',
    module_part: '109_dkzbj_h5_zbj_00042'
  },
  [ClassroomMessageControllerCommands.EMOJI_CLICK]: (id) => {
    return {
      module_ori: '表情列表，点击表情',
      module_part: '109_dkzbj_h5_zbj_00043',
      alternate: mergeLogAlternate({
        bq_type: id
      })
    }
  },
  [ClassroomMessageControllerCommands.QUICK_CLICK]: (id) => {
    return {
      module_ori: '点击【快捷回复标签】',
      module_part: '109_dkzbj_h5_zbj_00039',
      alternate: mergeLogAlternate({
        kjhf_type: id
      })
    }
  },
  [ClassroomMessageControllerCommands.IM_TYPE_SWITCH]: (type) => {
    return MSG_TYPE_LOG[type]
  }
}

/**
 * @description 设置直播间&回放全局埋点附加字段
 * @param {object} data 埋点数据
 */
export function setLiveGlobalLogAlternate (data) {
  const live = data.live ? 1 : 0
  const pagename = data.pagename
    ? `_${data.pagename}`
    : (live ? '_直播间' : '_回放')
  const logInfo = getLiveLogInfo(data)
  Store.$log.update({
    alternate: {
      pagename: BUSINESS_NAME + (pagename),
      project_id: APP_ID, // 业务项目ID（对应后台：期数类型）
      activity_id: logInfo.activityId, // 活动ID
      message_id: logInfo.messageId, // 消息ID
      zbj_lx: Store.getters['classroom/theme'], // 直播间样式
      is_live: live,
      room_id: data.roomId,
      SDK_id: pkg.dependencies['@wm/wm-live-sdk']
    }
  })
}
