import { APP_ID, AUTH_KEY } from '@/config'
/**
 * @constant 教室状态
 */
export const CLASSROOM_STATUS = {
  LOADING: {
    key: 'LOADING',
    message: '正在进入直播中...'
  },
  NO_LIVE: {
    key: 'NO_LIVE',
    message: '亲爱的同学，当前直播暂未开始哦～<br />先回顾下往期课程吧！'
  },
  PLAYING: {
    key: 'PLAYING'
  },
  NO_LIVE_CLASS_USER: {
    key: 'NO_LIVE_CLASS_USER',
    message: '您非直播班级学员无法进入直播间<br />如您确认为直播班级学员请联系您的老师'
  },
  NO_CLASS: {
    key: 'NO_CLASS',
    message: '您暂未分班，直播信息不存在'
  },
  NO_PLAYBACK_LIST: {
    key: 'NO_PLAYBACK_LIST',
    message: '回放正在生成请稍后查看'
  },
  PLAYBACK_LOADING: {
    key: 'PLAYBACK_LOADING',
    message: '努力进入中，请稍等…'
  }
}

/**
 * @constant 教室状态code映射
 */
export const CLASSROOM_STATUS_CODE = {
  0: 'NO_LIVE', // 未直播
  1: 'LOADING', // 直播中
  2: 'NO_LIVE', // 已结束、录播生成
  3: 'NO_PLAYBACK_LIST', // 回放转码中
  10: 'PLAYBACK_LOADING' // 回放已生成
}

/**
 * @constant 直播自定义信令
 */
export const LIVE_CUSTOM_EVENTS = {
  DV_SEND_QRCODE: 'DVSendQrCode',
  DV_FLASH_PURCHASE: 'DVFlashPurchase',
  DVPushCourse: 'DVPushCourse'
}

/**
 * @constant 直播中播放器事件
 */
export const LIVE_PLAYER_EVENTS = {
  PLAY: 'playing',
  PAUSE: 'pause',
  FULLSCREEN: 'fullscreen',
  QUITFULLSCREEN: 'quitFullscreen'
}

/**
 * @constant 直播配置类型
 */
export const LIVE_CONFIG_TYPES = {
  SELL: 'sell', // 带货配置
  STYLE: 'style', // 直播间样式
  EMOJIS: 'emojis', // 表情
  EMOJI_ICON: 'emoji-icon', // 表情icon
  QUICK: 'quick' // 快捷回复
}

/**
 * @description 直播样式类型键
 */
export const CLASSROOM_THEME_KEY = {
  DARK: 'dark',
  LIGHT: 'light'
}

/**
 * @description 直播样式类型
 */
export const CLASSROOM_THEME = {
  0: CLASSROOM_THEME_KEY.DARK,
  1: CLASSROOM_THEME_KEY.LIGHT
}

/**
  * @description 项目应用信息
*/
export const APP_CONFIG = {
  appKey: AUTH_KEY,
  appid: APP_ID
}
