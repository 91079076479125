/**
 * @description 设置导航栏标题
 * @param {string} title 标题
 */
export function setDocumentTitle (title) {
  try {
    const document = window.document
    document.title = title
    if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
      const i = document.createElement('iframe')
      i.src = '/replace_title'
      i.style.display = 'none'
      i.onload = () => {
        setTimeout(() => {
          i.remove()
        }, 9)
      }
      document.body.appendChild(i)
    }
  } catch (err) { }
}

/**
 * @description 判断当前为是否IOS
 */
export function isIOS () {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  return isiOS
}

/**
 * @description 判断当前是否为安卓
 */
export function isAndroid () {
  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
}

/**
 * @description 判断当前是否为pc端微信
 */
export function isPcWechat () {
  const u = navigator.userAgent
  return u.includes('Wechat')
}

/**
 * @description 获取url query
 * @param {string} key 要取值的query名
 * @returns {string | null}
 */
export function getQuery (key) {
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
  const query = location.href.split('?')
  const r = query.length > 1 ? query[1].match(reg) : ''
  return r ? unescape(r[2]) : null
}

/**
 * @description 获取#前的hash url query
 * @param {string} key 要取值的query名
 * @returns {string | null}
 */
export function getHashQuery (key) {
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  return r ? unescape(r[2]) : null
}

/**
 * obj转qs
 * @param {Object} query
 * @returns {String} queryString
 */
export function queryStringify (query = {}) {
  return Object.keys(query).filter(k => !!query[k]).map(k => `${k}=${query[k]}`).join('&')
}

/**
 * @description 获取当前环境下px对应的rem值
 * @param {number|string} pxNum
 * @returns {string} rem
 */
export function px2rem (pxNum) {
  if (pxNum.includes('%') || pxNum.includes('auto')) return pxNum
  return `${pxNum / 75}rem`
}

/**
 * @description 获取设计图上的px对应的用户的px
 * @param {number|string} pxNum
 * @param {string} unit 返回单位（不传则返回计算的数值）
 * @returns {string} px
 */
export function design2user (pxNum, unit = 'px') {
  const REM = parseFloat(window.getComputedStyle(document.getElementsByTagName('html')[0])['font-size'])
  return `${(pxNum / 75 * REM).toFixed(2)}${unit}`
}

/**
 * @description: 是否是对象
 * @param {object} obj
 * @return {Boolean}
 */
export function isObject (obj) {
  return typeof obj === 'object' && obj != null
}

/**
 * @description: 是否是数组
 * @param {array} arr
 * @return {Boolean}
 */
export function isArray (arr) {
  return Array.isArray(arr)
}

/**
 * @description: 校验是否是合法json串
 * @param {String} value
 * @return {Boolean}
 */
export function isJson (value) {
  try {
    const json = JSON.parse(value)
    if (isObject(json)) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

/**
 * @description 页面是否需要vconsole
 * @returns {boolean}
 */
export function needVconsole () {
  return window.location.href.includes('vconsole=wm')
}

/**
 * @description 页面是否需要vconsole
 * @returns {boolean}
 */
export function getErrorMsg (error) {
  return error && (error instanceof Error ? error.message : JSON.stringify(error))
}

/**
 * @description 设置本地缓存
 * @param {string} key
 * @param {any} value
 */
export function setStorage (key, value) {
  let setValue = value
  // 如果是对象类型转json串
  if (isObject(setValue)) {
    setValue = JSON.stringify(setValue)
  }
  localStorage.setItem(key, setValue)
}

/**
 * @description 获取本地缓存数据
 * @param {string} key
 * @param {any} defaultVal
 */
export function getStorage (key, defaultVal) {
  let storage = localStorage.getItem(key)
  // 如果是json串解析一下
  if (isJson(storage)) {
    storage = JSON.parse(storage)
  }
  return storage || defaultVal
}

/**
 * @description 删除本地缓存数据
 * @param {string} key
 */
export function removeStorage (key) {
  localStorage.removeItem(key)
}

/**
 * @constant 是否是生产环境
 */
export function isProdEnv () {
  return process.env.VUE_ENV === 'prod'
}

/**
 * @description: 生成min-max的随机数
 * @param {number} min
 * @return {number} max
 */
export const intervalRandom = function (min, max) {
  const range = max - min
  const rand = Math.random()
  const num = min + Math.round(rand * range)
  return num
}

/**
 * @description 按指定key对数组排序
 * @param {array} arr 原数组
 * @param {string} key 指定key排序的名称
 * @param {boolean} asc 是否升序 默认true
 * @returns {array} 排序后的数组
 */
export function sortByKey (arr, key, asc = true) {
  const compare = (a, b) => {
    const valueA = a[key]
    const valueB = b[key]
    if (asc) {
      return valueA - valueB
    } else {
      return valueB - valueA
    }
  }
  return arr.sort(compare)
}

/**
 * @description: 格式话json串
 * @param {String} value
 * @return {Boolean}
 */
export function formatJson (value) {
  if (Number(value)) return value
  try {
    const json = JSON.parse(value)
    return json
  } catch (error) {
    return value
  }
}

/**
 * @constant 复制文案
 */
export function copyText (text) {
  // 数字没有 .length 不能执行selectText 需要转化成字符串
  const value = text.toString()
  let input = document.querySelector('#copy-input')
  if (!input) {
    input = document.createElement('textarea')
    input.id = 'copy-input'
    input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件

    // input不能隐藏，隐藏就复制不了，所以用定位脱离文档流
    input.style.position = 'absolute'
    input.style.left = '-1000px'
    input.style.zIndex = '-1000'
    document.body.appendChild(input)
  }

  input.value = value

  // ios必须先选中文字且不支持 input.select();
  // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
  // 选择文本。 createTextRange(setSelectionRange)是input方法
  const selectText = (textbox, startIndex, stopIndex) => {
    if (textbox.createTextRange) { // ie
      const range = textbox.createTextRange()
      range.collapse(true)
      range.moveStart('character', startIndex)// 起始光标
      range.moveEnd('character', stopIndex - startIndex)// 结束光标
      range.select() // 不兼容苹果
    } else { // firefox/chrome
      textbox.setSelectionRange(startIndex, stopIndex)
      textbox.focus()
    }
  }

  selectText(input, 0, value.length)
  if (document.execCommand('copy')) {
    document.execCommand('copy')
  }
  input.blur()
}

/**
 * @description 字符超出n位数部分显示指定符号
 * @param {string} val 需要操作的字符串
 * @param {number} n 限制的超出位数
 * @param {string} suffix 超出部分以suffix结束，默认...
 */
export function overflowEllipsis (val, n, suffix = '...') {
  if (!val) return ''
  if (val.length <= n) return val
  return val.substr(0, n) + suffix
}
