// import {
//   ClassroomMessageController,
//   ClassroomMessageList,
//   ClassroomNotice,
//   ClassroomPlaybackController,
//   ClassroomPlaybackPlayer,
//   ClassroomPlayer
// } from '@wm/wm-live-components'
import wmLiveComponents from '@wm/wm-live-components'

export default Vue => {
  Vue
    .use(wmLiveComponents)
    // .use(ClassroomMessageController)
    // .use(ClassroomMessageList)
    // .use(ClassroomNotice)
    // .use(ClassroomPlaybackController)
    // .use(ClassroomPlaybackPlayer)
    // .use(ClassroomPlayer)
}
