import { getLiveConfig, getRoomTabs, getDefaultTab, accessFail } from '@/helper/classroom'
import {
  CLASSROOM_STATUS,
  LIVE_CONFIG_TYPES,
  CLASSROOM_THEME
} from '@/helper/classroom/types'
import { isArray } from '@/utils'
import Router from '@/router'
import { NOT_LIVE_AUTH_CODE } from '@/config'

export default {
  namespaced: true,
  state: {
    classRoomStatus: CLASSROOM_STATUS.LOADING, // 直播间状态
    signInfo: null // 签名相关信息
  },
  getters: {
    // 营销配置
    marketConfig: state => getLiveConfig(LIVE_CONFIG_TYPES.SELL, state.signInfo.configList),

    // 直播间tabs
    tabs: (state) => getRoomTabs(state.signInfo.roomConfigs),

    // 默认tab
    defaultTab: (state, getters) => getDefaultTab(getters.tabs),

    // 快捷回复列表
    quick: state => getLiveConfig(LIVE_CONFIG_TYPES.QUICK, state.signInfo.configList),

    // 表情回复列表
    emoji: state => {
      let res = getLiveConfig(LIVE_CONFIG_TYPES.EMOJIS, state.signInfo.configList)
      // 兼容表情存在style配置里的情况
      if (!res) {
        res = getLiveConfig(LIVE_CONFIG_TYPES.STYLE, state.signInfo.configList).emoji
      }
      return res || []
    },

    // 表情icon开关
    emojiSwitch: state => {
      const res = state.signInfo.configList.some(item => item.type === LIVE_CONFIG_TYPES.EMOJI_ICON)
      return res
    },

    // gif表情开关
    gifSwitch: (state, getters) => {
      const emoji = getters.emoji
      // 兼容旧数据数组类型
      if (isArray(emoji)) return getters.emoji.length > 0
      return !!emoji
    },

    // 是否显示表情
    showEmoji: (state, getters) => {
      const gifSwitch = getters.gifSwitch
      const emojiSwitch = getters.emojiSwitch
      return emojiSwitch || gifSwitch
    },

    // 直播间样式
    theme: state => {
      const { value } = getLiveConfig(LIVE_CONFIG_TYPES.STYLE, state.signInfo.configList)
      return CLASSROOM_THEME[value]
    },

    // 营销带货
    market: state => getLiveConfig(LIVE_CONFIG_TYPES.MARKET, state.signInfo.configList),

    // 是否是新版直播带货
    liveSell: (state, getters) => {
      const market = getters.market || {}
      return market.liveSell || false
    },

    // 是否有水印
    watermark: (state, getters) => {
      const { watermark = false } = getLiveConfig(LIVE_CONFIG_TYPES.STYLE, state.signInfo.configList)
      return watermark
    }
  },
  mutations: {
    SET_STATUS (state, payload) {
      state.classRoomStatus = payload
    },
    SET_SIGN (state, payload) {
      state.signInfo = payload
    }
  },
  actions: {
    /**
     * @description 更新直播间状态
     */
    updateClassRoomStatus ({ commit }, status) {
      commit('SET_STATUS', status)
    },
    /**
     * @description 获取当前用户直播间签名
     */
    async getSign ({ commit }, params) {
      try {
        const data = await this.$apis.classroom.getSign(params)
        commit('SET_SIGN', data)
        return data
      } catch (error) {
        const { code } = error
        if (NOT_LIVE_AUTH_CODE.includes(code)) { // 未报名
          Router.replace({ name: 'NotAuth', params: { classroom: params.roomId } })
          throw new Error('NotAuth')
        }
        if ([106010].includes(code)) { // 回放被下架
          Router.replace({ name: 'PlayBackList', params: { classroom: params.roomId } })
          throw new Error('videoNotFound')
        }
        accessFail(error)
      }
    }
  }
}
