
import Store from '@/store'
import { Dialog } from 'vant'
import { Classroom, ClassroomTypes } from '@wm/wm-live-components'
import { APP_ID, LOG_SERVICE_NAME } from '@/config'
import { CLASSROOM_STATUS, CLASSROOM_STATUS_CODE } from '@/helper/classroom/types'
import { setLiveGlobalLogAlternate } from '@/helper/classroom/log'
import { getErrorMsg, setDocumentTitle, needVconsole, isProdEnv, setStorage } from '@/utils'
import { getLiveLogInfo } from './index'

/**
 * @description 直播间签名信息获取&准入校验
 * @param {string} roomId 直播间号
 * @param {string} live 是否是直播
 */
export async function classRoomAccess ({ roomId, live }) {
  let signInfo = Store.state.classroom.signInfo
  try {
    signInfo = await Store.dispatch('classroom/getSign', { roomId })
    const theme = Store.getters['classroom/theme']
    if (!theme) {
      throw Error('can not find the right style type')
    }
    setStorage('wmvSign', signInfo.wmvSign)
  } finally {
    setLiveGlobalLogAlternate({
      ...signInfo,
      live
    })
  }
  return signInfo
}

/**
 * @description 初始化直播间
 * @param {string} roomId 直播间号
 */
export async function initClassRoom (roomId) {
  const signInfo = await classRoomAccess({
    roomId: roomId,
    live: true
  })

  // 设置直播间标题
  setDocumentTitle(signInfo.roomTitle)

  // 直播中只要不是开播状态就显示成NO_LIVE状态样式，并且不初始化直播
  if (signInfo.liveStatus !== 1) {
    const noLive = CLASSROOM_STATUS[CLASSROOM_STATUS_CODE[2]]
    Store.dispatch('classroom/updateClassRoomStatus', noLive)
    throw new Error('NO_LIVE')
  }

  const config = {
    ...getLiveconfig(signInfo),
    classId: getLiveLogInfo(signInfo).messageId,
    live: true
  }

  sdkReadyListener(ClassroomTypes.ClassroomEvents.INITED)

  return config
}

/**
 * @description 初始化回放
 * @param {string} roomId 直播间号
 */
export async function initPlayback (roomId) {
  const signInfo = await classRoomAccess({
    roomId: roomId,
    live: false
  })

  // 设置直播间标题
  setDocumentTitle(signInfo.roomTitle)

  Store.dispatch('classroom/updateClassRoomStatus', CLASSROOM_STATUS.PLAYING)

  const config = {
    ...getLiveconfig(signInfo),
    classId: getLiveLogInfo(signInfo).messageId,
    live: false
  }

  sdkReadyListener(ClassroomTypes.ClassroomPlaybackEvents.INITED)

  return config
}

/**
 * @description 获取直播初始化参数
 */
function getLiveconfig (data) {
  const DEFAULT_NICKNAME = '学员'
  const DEFAULT_AVATAR = '//wm-front-common.weimiaocaishang.com/dv-live-class/img/icon_defalut.png'

  return {
    wmvSign: data.wmvSign,
    env: isProdEnv() ? 'production' : 'develop',
    sign: data.imUsersig,
    serviceName: LOG_SERVICE_NAME,
    appid: APP_ID,
    imappid: data.imAppId,
    roomid: data.roomId,
    groupid: data.imGroupId,
    userid: data.imUid,
    nickname: data.nickname || DEFAULT_NICKNAME,
    role: data.role,
    avatar: data.avatarUrl || DEFAULT_AVATAR,
    log: needVconsole(),
    liveStateInterval: [180000, 300000]
  }
}

/**
 * @description 监听直播间初始化状态
 */
function sdkReadyListener (event) {
  Classroom.instance.event.$once(event, () => {
    Store.dispatch('classroom/updateClassRoomStatus', CLASSROOM_STATUS.PLAYING)
  })
}

/**
 * @description 直播间准入失败
 */
export function accessFail (error) {
  const { desc = '' } = error
  const errorMsg = getErrorMsg(error)
  Dialog.alert({
    title: '提示',
    message: desc || errorMsg || '进入直播间失败',
    showConfirmButton: false
  })
  Store.$log.show({
    pre_page: document.referrer,
    current_page: window.location.href,
    module_part: '109_xbyzb_h5_error_00002',
    module_ori: '【直播/回放页面加载失败】',
    alternate: JSON.stringify({
      pagename: '大咖直播-直播间页面异常',
      error: errorMsg
    })
  })
}
