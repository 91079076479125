<template>
  <div class="horizontal-screen">
    <div v-if="isShowDefault" class="default">
      <img src="@/assets/common/icon_rotate.png" alt="">
      <p>当前页面暂不支持横屏展示！</p>
      <p>为了避免影响您的学习体验，请您竖屏后再进行浏览</p>
    </div>
    <div v-else-if="isShowClassRoom" class="classroom">
      <div class="btn" @click="handleVideoFullScreen">戳我全屏观看</div>
    </div>
  </div>
</template>
<script>
import { Classroom } from '@wm/wm-live-components'

const HORIZONTAL_SCREEN_STATUS = {
  DEFAULT: 'default',
  CLASS_ROOM: 'class_room'
}
const CLASS_ROOM_ROUTES = [
  'LiveClassRoom',
  'PlayBackClassRoom'
]
export default {
  data () {
    return {
      status: HORIZONTAL_SCREEN_STATUS.DEFAULT
    }
  },
  computed: {
    isShowDefault () { // 显示默认横屏提示
      return this.status === HORIZONTAL_SCREEN_STATUS.DEFAULT
    },
    isShowClassRoom () { // 显示直播间自定义横屏提示
      return this.status === HORIZONTAL_SCREEN_STATUS.CLASS_ROOM
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler (newVal) {
        // 播放器实例未生成时不显示戳我全屏
        if (!Classroom.instance.player) return
        if (CLASS_ROOM_ROUTES.find(v => v === newVal)) {
          this.status = HORIZONTAL_SCREEN_STATUS.CLASS_ROOM
        }
      }
    }
  },
  methods: {
    /**
     * @description 直播间视频全屏
     */
    handleVideoFullScreen () {
      const video = document.querySelector('video')
      if (video.paused) {
        Classroom.instance.player.play()
      }
      setTimeout(() => {
        if (typeof video.webkitRequestFullScreen === 'function') {
          video.webkitRequestFullScreen()
        } else if (typeof video.webkitEnterFullscreen === 'function') {
          video.webkitEnterFullscreen()
        }
      }, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.horizontal-screen {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > div {
    width: 100%;
    height: 100%;
  }

  .default {
    text-align: center;
    padding-top: 20px;
    background-color: #fff;

    img {
      width: 179px;
      height: 143px;
      padding-bottom: 10px;
    }

    p {
      margin: 0;
      font-size: 24px;
      color: #333;
      line-height: 45px;
    }
  }

  .classroom {
    @include horVerCenter;

    background-color: rgba($color: #000, $alpha: 0.7);

    .btn {
      @include horVerCenter;

      padding: 24px 36px;
      font-size: 40px;
      color: #ab4700;
      border-radius: 14px;
      background: linear-gradient(0deg, rgba(250, 199, 126, 1) 0%, rgba(255, 241, 219, 1) 100%);
    }
  }
}
</style>
