import { COMMMON_SHARE_CONFIG } from '@/config'
import { isJson } from '@/utils'

import Store from '@/store'

/**
 * @description 获取埋点现有附加参数
 * @returns {object}
 */
export const getLogAlternate = () => {
  const alternate = Store.$log.statistics.options.body.alternate
  return isJson(alternate) ? JSON.parse(alternate) : alternate
}

/**
 * @description 与现有埋点附加字段合并
 * @param {object} alternate 需要合并的附加字段
 * @returns {object} 合并后的埋点附加字段
 */
export const mergeLogAlternate = (alternate) => {
  return JSON.stringify({
    ...getLogAlternate(),
    ...alternate
  })
}

/**
 * @description 心跳埋点上报
 * @param {vue} vm vue实例
 * @param {object} option 心跳埋点参数
 */
export const heartbeatLog = (vm, option) => {
  const heartbeat = vm.$log.heartbeat({
    event: 'heart',
    current_page: window.location.href,
    ...option
  })
  console.log(heartbeat.isRate, '是否在上报比例')
  heartbeat.start()
  vm.$on('hook:beforeDestroy', () => {
    heartbeat.stop()
  })
}

/**
 * @description 页面访问埋点上报（访问&心跳）
 * @param {vue} vm vue实例
 * @param {object} option show：访问埋点参数 heart：心跳埋点参数
 */
export const visitLog = (vm, { module_ori: moduleOri, show, heart }) => {
  vm.$log.show({
    current_page: window.location.href,
    module_ori: moduleOri,
    ...show
  })
  heartbeatLog(vm, {
    module_ori: moduleOri,
    ...heart
  })
}

/**
 * @description 分享埋点上报
 * @param {vue} vm vue实例
 * @param {object} option 分享埋点参数
 * @param {object} shareConfig 分享配置参数，默认为通用分享参数
 */
export const shareLog = (vm, option, shareConfig = {}) => {
  // 分享配置参数
  const data = {
    ...COMMMON_SHARE_CONFIG,
    link: window.location.href,
    ...shareConfig
  }
  /**
   * @description 分享埋点上报
   * @param {number} type 分享类型 1: 分享好友 2: 分享到朋友圈
   */
  const log = (type) => {
    vm.$log.share({
      current_page: window.location.href,
      ...option,
      alternate: mergeLogAlternate({
        share_type: type
      })
    })
  }
  vm.$wx.onTimelineShareData(data, () => log(2)) // 分享朋友圈
  vm.$wx.onAppMessageShareData(data, () => log(1)) // 分享好友
}
