import WMStatistics from '@wm/wm-burying-point'
import { LOG_SERVICE_NAME } from '@/config'
import { isProdEnv } from '@/utils'

const env = isProdEnv() ? 'production' : 'development'
const mode = 'img'

const log = new WMStatistics(LOG_SERVICE_NAME, { env, mode })

export const registerLog = (Vue, Store) => {
  Vue.use(log, {
    name: '$log',
    directiveName: 'log'
  })
  Store.$log = log
}
