<template>
  <div id="app">
    <HorizontalScreen v-if="showMask" />
    <RouterView />
  </div>
</template>
<script>
import HorizontalScreen from '@/components/HorizontalScreen'

export default {
  components: {
    HorizontalScreen
  },
  data () {
    return {
      showMask: false
    }
  },
  mounted () {
    this.checkOrient()
    const evt = 'onorientationchange' in window ? 'orientationchange' : 'resize'
    window.addEventListener(evt, () => {
      this.checkOrient()
    })
  },
  methods: {
    // 通过window.orientation来判断设备横竖屏
    checkOrient () {
      if (window.orientation === 0 || window.orientation === 180) {
        // 竖屏的时候
        this.showMask = false
      } else if (window.orientation === 90 || window.orientation === -90) {
        // 横屏的时候
        this.showMask = true
      }
    }
  }
}
</script>
