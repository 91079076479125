import service from './service'

export default {
  user: {
    wxLogin: params => service.get('/passport/front/data', { params }),
    getUserInfo: params => service.get('/dv/m/wxauth/user', { params }),
    getWxConfig: params => service.get('/dv/wxauth/package', { params }),
    getUserType: params => service.get('/dv/m/active/user_type', { params })
  },
  classroom: {
    getSign: params => service.get('/open/live/proxy/enter/getUserSignAndConfig', { params, errorCustom: true })
  },
  playback: {
    getPlayBackList: params => service.get('/open/live/service/video/getLiveVideoList', { params })
  }
}
