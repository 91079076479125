import Vue from 'vue'
import Vuex from 'vuex'

import classroom from './modules/classroom'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {
    // 直播间签名信息
    classRoomSignInfo: state => state.classroom.signInfo
  },
  mutations: {},
  actions: {},
  modules: {
    classroom,
    user
  }
})
