/**
 * @constant 已缓存过的图片map
 */
export const loadedImageList = new Map()

/**
 * @description 等待图片加载完毕
 * @param {string} url 图片链接
 * @param {number} timeOut 超时时间
 * @returns {Promise} 图片加载结果
 */
export function waitImageLoaded (url, timeOut = 2000) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('url is required'))
    // 如果该图片已下载过，则直接返回成功
    if (loadedImageList && loadedImageList.has(url)) return resolve()
    let img = new Image()
    img.src = url
    img.addEventListener('load', () => {
      loadedImageList && loadedImageList.set(url, true)
      resolve()
    })
    img.addEventListener('error', e => {
      reject(e)
    })
    if (timeOut !== 0) {
      setTimeout(() => {
        reject(new Error(`Time out (${timeOut}ms)`))
      }, timeOut)
    }
    img = null
  })
}

/**
 * @description 等待批量图片加载完毕
 * @param {Array} urls 图片链接列表
 */
export async function waitImageListLoaded (urls) {
  for (let i = 0; i < urls.length; i += 1) {
    try {
      await waitImageLoaded(urls[i], 0)
    } catch (e) {
      console.error(e)
    }
  }
}
