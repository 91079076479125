import promiseWx from '@/libs/promiseWx'
import { Dialog } from 'vant'
import { AUTH_KEY } from '@/config'
import Cookies from 'js-cookie'
import { getHashQuery, setStorage, removeStorage, overflowEllipsis } from '@/utils'

export default {
  namespaced: true,
  state: {
    userInfo: null,
    wxConfig: null,
    userType: ''
  },
  getters: {
    // 水印内容
    watermarkContent: state => {
      if (!state.userInfo) return ''
      const { nickname = '', mid } = state.userInfo
      return overflowEllipsis(nickname, 6, '...') +
      mid
    }
  },
  mutations: {
    SET_USER (state, payload) {
      state.userInfo = payload
    },
    SET_WX_CONFIG (state, payload) {
      state.wxConfig = payload
    },
    SET_USER_TYPE (state, payload) {
      state.userType = payload
    }
  },
  actions: {
    /**
     * @description 获取微信config
     */
    async getWxConfig ({ commit }) {
      try {
        const wxConfig = await this.$apis.user.getWxConfig({
          // url: 'https://test-live-dv.weimiaocaishang.com/#/live-classroom/10000798?channel=channel_1000',
          // doman: 'test-live-dv.weimiaocaishang.com'
          url: location.href,
          doman: location.hostname
        })
        await promiseWx.mount({
          appId: wxConfig.appId,
          nonceStr: wxConfig.nonceStr,
          signature: wxConfig.signature,
          timestamp: String(wxConfig.timestamp)
        })
        commit('SET_WX_CONFIG', wxConfig)
      } catch (error) {
        console.log('error', error)
        Dialog.alert({
          title: '提示',
          message: `获取公众号配置失败\n${error.errMsg}`,
          confirmButtonText: '刷新重试'
        }).then(() => {
          window.location.reload()
        })
        throw error
      }
    },
    /**
     * @description 获取用户信息
     */
    async getUserInfo ({ commit }, params) {
      try {
        const data = await this.$apis.user.getUserInfo(params)
        const userInfo = {
          mid: data.id,
          mobile: data.mobile,
          unionid: data.unionid,
          nickname: data.wx_body.nickname,
          openid: data.wx_body.openid
        }
        this.$log.update({
          mid: userInfo.mid,
          openid: userInfo.openid,
          unionid: userInfo.unionid
        })
        commit('SET_USER', userInfo)
      } catch (error) {
        console.log(error, '获取用户信息失败')
        throw error
      }
    },
    /**
     * @description: 换取token
     */
    async getToken () {
      try {
        const token = getHashQuery('token')
        const { jwt } = await this.$apis.user.wxLogin({ token, appid: AUTH_KEY })
        if (token && jwt) {
          setStorage('jwt', jwt)
        }
      } catch (error) {
        console.log(error, '获取jwt失败')
        throw error
      } finally {
        removeStorage('goWxLogin')
      }
    },
    /**
     * @description: 获取用户类型
     * 5分钟内随机请求用户类型接口，以免并发太大，给服务端造成压力
     */
    async getUserType ({ commit }) {
      const data = await this.$apis.user.getUserType()
      commit('SET_USER_TYPE', data.user_type)
      // 设置4小时缓存
      const inFourHours = new Date(new Date().getTime() + 4 * (60 * 60 * 1000))
      Cookies.set('user_type', data.user_type, { expires: inFourHours })
    }
  }
}
