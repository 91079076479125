
/* 直播间 */const LiveClassRoom = () => import(/* webpackChunkName: "live-classroom" */ '@/views/LiveClassRoom')
/* 回放 */const PlayBackClassRoom = () => import(/* webpackChunkName: "playback-classroom" */ '@/views/PlayBackClassRoom')
/* 回放列表 */const PlayBackList = () => import(/* webpackChunkName: "playback-list" */ '@/views/PlayBackList')
/* 404 */const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
/* 无权限页面 */const NotAuth = () => import(/* webpackChunkName: "not-auth" */ '@/views/NotAuth')
/* 无网络页面 */const NoNetwork = () => import(/* webpackChunkName: "not-found" */ '@/views/NoNetwork')
/* 微信授权页 */const WxAuth = () => import(/* webpackChunkName: "wx-auth" */ '@/views/WxAuth')

export default [
  {
    path: '/wx-auth',
    name: 'WxAuth',
    component: WxAuth,
    props: true,
    meta: {
      notAuth: true
    }
  },
  {
    path: '/not-auth/:text?',
    name: 'NotAuth',
    component: NotAuth,
    props: true,
    meta: {
      title: '无权限'
    }
  },
  {
    path: '/no-network',
    name: 'NoNetwork',
    component: NoNetwork,
    props: true,
    meta: {
      notAuth: true,
      title: '无网络'
    }
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    props: true,
    meta: {
      title: '微淼'
    }
  },
  {
    path: '/live-classroom/:classroom',
    name: 'LiveClassRoom',
    component: LiveClassRoom,
    meta: {
      title: '直播间',
      isLive: 1
    }
  },
  {
    path: '/live-classroom-trad/:classroom',
    name: 'LiveClassRoom',
    component: LiveClassRoom,
    meta: {
      title: '直播间',
      isLive: 1
    }
  },
  {
    path: '/playback-classroom/:classroom',
    name: 'PlayBackClassRoom',
    component: PlayBackClassRoom,
    meta: {
      title: '回放',
      isLive: 0
    }
  },
  {
    path: '/playback-classroom-trad/:classroom',
    name: 'PlayBackClassRoom',
    component: PlayBackClassRoom,
    meta: {
      title: '回放',
      isLive: 0
    }
  },
  {
    path: '/playback-list/:classroom',
    name: 'PlayBackList',
    component: PlayBackList,
    meta: {
      title: '回放列表'
    }
  }
]
