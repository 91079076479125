import { isProdEnv } from '@/utils'

/**
 * @constant 默认渠道号
 */
export const DEFAULT_CHANNEL = 'channel_1000'

/**
 * @constant 业务线名称(中文)
 */
export const BUSINESS_NAME = '大咖直播间'

/**
 * @constant 埋点服务名
 */
export const LOG_SERVICE_NAME = 'dkzbj'

/**
 * @constant 大v直播间项目id
 */
export const APP_ID = '109'

/**
 * @constant 授权中心标识
 */
export const AUTH_KEY = 'xiaobai'

/**
 * @constant 用户未授权code
 */
export const NOT_AUTH_CODE = [20005, 101011, 34001]

/**
 * @constant 直播间准入失败code
 */
export const NOT_LIVE_AUTH_CODE = [100905, 100907]

/**
 * @constant 全局路由报错不拦截code
 */
export const ROUTE_NOT_INTERCEPT_CODE = [
  ...NOT_AUTH_CODE,
  ...NOT_LIVE_AUTH_CODE
]

/**
 * @constant 微信授权授权地址
 */
export const WM_LOGIN = isProdEnv()
  ? 'https://passport.weimiaocaishang.com/login'
  : 'https://test-passport.weimiaocaishang.com/login'

/**
 * @constant 公共分享配置
 */
export const COMMMON_SHARE_CONFIG = {
  title: '名师天团带你解锁财富密码', // 分享标题
  desc: '0元看理财创业知识晚会！直播间还有多重大奖等你拿！', // 分享描述
  imgUrl: 'https://wm-front-common.weimiaocaishang.com/dv-live-class/img/icon_share.png'
}
