import { Toast } from 'vant'
import Router from '@/router'
import { NOT_AUTH_CODE, NOT_LIVE_AUTH_CODE } from '@/config'

export const ResponseStatusCallback = (response) => {
  const { data: { code, msg, desc } } = response
  const { errorCustom } = response.config

  // 微信未授权、去授权中心授权
  if (NOT_AUTH_CODE.includes(code)) {
    Router.replace({
      name: 'WxAuth',
      replace: true,
      query: {
        url: location.href
      }
    })
    return Promise.reject(response.data)
  }

  // 用户未报名，准入失败
  if (NOT_LIVE_AUTH_CODE.includes(code)) {
    return Promise.reject(response.data)
  }

  // 需要自定义错误行为的接口
  if (errorCustom) return Promise.reject(response.data)
  Toast(msg || desc)
  return Promise.reject(response.data)
}
