const wxPromisify = {}

const wxConfigList = [
  'getLocalImgData',
  'updateTimelineShareData',
  'updateAppMessageShareData',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'onMenuShareQQ',
  'onMenuShareWeibo',
  'onMenuShareQZone',
  'startRecord',
  'stopRecord',
  'onVoiceRecordEnd',
  'playVoice',
  'pauseVoice',
  'stopVoice',
  'onVoicePlayEnd',
  'uploadVoice',
  'downloadVoice',
  'chooseImage',
  'previewImage',
  'uploadImage',
  'downloadImage',
  'translateVoice',
  'getNetworkType',
  'openLocation',
  'getLocation',
  'hideOptionMenu',
  'showOptionMenu',
  'hideMenuItems',
  'showMenuItems',
  'hideAllNonBaseMenuItem',
  'showAllNonBaseMenuItem',
  'closeWindow',
  'scanQRCode',
  'chooseWXPay',
  'openProductSpecificView',
  'addCard',
  'chooseCard',
  'openCard'
]

const syncApiList = ['ready', 'error'].concat(wxConfigList)

syncApiList.forEach(key =>
  Object.defineProperty(wxPromisify, key, {
    enumerable: true,
    configurable: false,
    writable: false,
    value: wx[key]
  })
)
let _wxReadyPromisify = null

const asyncApiList = {
  config (config) {
    console.log(config, 'config')
    config.jsApiList = wxConfigList
    wx.config(config)
    _wxReadyPromisify = new Promise((resolve, reject) => {
      wx.ready(resolve)
      wx.error(reject)
    })
    return _wxReadyPromisify
  },
  checkReady () {
    return _wxReadyPromisify || Promise.reject(new Error('wx:请先初始化js-sdk'))
  },
  /**
   * 设置分享
   *
   * @param {Object} data
   *  {
   *    title: '', // 分享标题
   *    desc: '', // 分享描述
   *    link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
   *    imgUrl: '' // 分享图标
   *  }
   */
  setShareData (data) {
    return Promise.all([this.setTimelineShareData(data), this.setAppMessageShareData(data)])
  },
  /**
   * 自定义分享朋友圈内容
   * @param {*} data
   * @param {Function} success 配置成功回调
   */
  setTimelineShareData (data, success) {
    return this.checkReady().then(() => {
      wx.updateTimelineShareData({ ...data, success })
    })
  },
  /**
   * 自定义分享朋友内容
   * @param {*} data
   * @param {Function} success 配置成功回调
   */
  setAppMessageShareData (data, success) {
    return this.checkReady().then(() => {
      wx.updateAppMessageShareData({ ...data, success })
    })
  },
  /**
   * 自定义分享朋友内容(待废弃)
   * @param {*} data
   * @param {Function} success 分享成功回调
   */
  onAppMessageShareData (data, success) {
    return this.checkReady().then(() => {
      wx.onMenuShareAppMessage({ ...data, success })
    })
  },
  /**
   * 自定义分享朋友圈内容(待废弃)
   * @param {*} data
   * @param {Function} success 分享成功回调
   */
  onTimelineShareData (data, success) {
    return this.checkReady().then(() => {
      wx.onMenuShareTimeline({ ...data, success })
    })
  }

}

Object.keys(asyncApiList).forEach(key =>
  Object.defineProperty(wxPromisify, key, {
    enumerable: true,
    configurable: false,
    writable: false,
    value: asyncApiList[key]
  })
)

export default wxPromisify
