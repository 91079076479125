import axios from 'axios'
import Router from '@/router'
import { APP_ID } from '@/config'
import { getStorage } from '@/utils'
import { ResponseStatusCallback } from './responseHooks'

const service = axios.create({
  baseURL: '/api',
  timeout: 10000,
  withCredentials: true
})

service.interceptors.request.use(async config => {
  config.headers['h-app-id'] = APP_ID
  config.headers.authorization = getStorage('jwt', '')
  config.headers['x-wmv-sign'] = getStorage('wmvSign', '')

  return config
})

service.interceptors.response.use(response => {
  const { data: { code, data } } = response
  const successCodes = [20000, 0, 1000]
  if (successCodes.includes(code)) {
    return data
  }

  return ResponseStatusCallback(response)
}, error => {
  Router.replace({
    name: 'NoNetwork',
    query: {
      hash: location.hash
    }
  })

  return Promise.reject(error)
})

export default service
