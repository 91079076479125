import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

// Vconsole
import '@/libs/vconsole'

// 接口
import { registerApi } from './api'

// vant ui
import registerVant from './libs/vant'

// vant wm-live-components
import registerWmLiveComponents from './libs/wm-live-components'

// 公用样式
import '@/scss/index.scss'

// 微信
import promiseWx from './libs/promiseWx'

// log
import { registerLog } from '@/libs/log'

import { setDocumentTitle } from '@/utils'

// app 与 web 通信
import bridge from './libs/bridge'
Vue.prototype.$bridge = bridge

Vue.prototype.$setTitle = setDocumentTitle
Vue.use(promiseWx)
sync(store, router)
registerVant(Vue)
registerWmLiveComponents(Vue)
registerApi(Vue, store)
registerLog(Vue, store)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
