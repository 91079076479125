import {
  Overlay,
  Toast,
  Dialog,
  Loading,
  Icon
} from 'vant'

export default Vue => {
  Vue
    .use(Overlay)
    .use(Toast)
    .use(Dialog)
    .use(Loading)
    .use(Icon)
}
