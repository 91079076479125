import { COMMMON_SHARE_CONFIG } from '@/config'
import { CLASSROOM_STATUS } from '@/helper/classroom/types'
import { waitImageListLoaded } from '@/utils/img'
import Store from '@/store'
import { sortByKey, formatJson } from '@/utils'

export * from './init'

/**
 * @description 根据类型获取直播配置数据
 * @param {string} type
 * @param {array} data 配置列表
 * @param {string} key 所需属性key值 默认为json
 * @returns {any} 返回配置信息
 */
export function getLiveConfig (type, data, key = 'json') {
  const value = data && data.length > 0 && (data.find(v => v.type === type) || {})[key]
  if (key === 'json') {
    return value && JSON.parse(value)
  }
  return value
}

/**
 * @description 获取直播间tabs
 * @param {object} data
 * @returns {object} 返回直播间tabs
 */
export function getRoomTabs (data) {
  const style = getRoomConfig(data, 'style')
  const styleKeys = Object.keys(style)

  // 兼容老数据有问题的数据情况
  if (styleKeys.length < 1) {
    return [{
      key: 'im',
      name: '课堂互动',
      show: true,
      styleType: 'im'
    }]
  }
  const styleList = styleKeys.reduce((prev, key) => {
    prev.push({
      ...formatJson(style[key]),
      key
    })
    return prev
  }, [])
  return sortByKey(styleList, 'sort')
}

/**
 * @description 获取直播间默认tab
 * @param {object} data
 * @returns {object} 返回直播间默认tab
 */
export function getDefaultTab (data) {
  const defaultTab = data.find(item => item.show) || data[0]
  return defaultTab && defaultTab.key
}

/**
 * @description 返回业务直播间配置信息
 * @param {object} data 配置数据
 * @param {string} type 配置类型
 * @param {string} key 具体属性
 * @returns {any} 返回配置信息
 */
export function getRoomConfig (data, type, key) {
  if (!data) return ''
  if (!data[type]) return ''
  const res = key ? data[type][key] : data[type]
  if (!res) return ''

  return formatJson(res)
}

/**
 * @description 获取直播间状态对应组件名
 * @param {string} status 当前直播间状态
 * @param {string} prefix 组件前缀（区分传统|分屏直播间）
 * @returns {string} 组件名
 */
export function getClassRoomStatusComponent (status, prefix) {
  // 基础文案提示
  const baseTextComponents = [
    CLASSROOM_STATUS.LOADING.key,
    CLASSROOM_STATUS.NO_CLASS.key,
    CLASSROOM_STATUS.NO_LIVE_CLASS_USER.key,
    CLASSROOM_STATUS.NO_PLAYBACK_LIST.key
  ]
  if (baseTextComponents.find(key => key === status)) {
    return prefix + 'BaseText'
  }

  // 自定义文案提示（根据状态常量名称规则转成对应组件名）
  const name = status
    .split('_')
    .map(v => v.charAt(0) + v.slice(1).toLowerCase())
    .join('')
  return prefix + name
}

/**
 * @description 提前批量加载营销配置图片并缓存
 */
export function loadMarketImgs () {
  const marketConfig = Store.getters['classroom/marketConfig']
  const imgList = Object.keys(marketConfig).filter(k => !!marketConfig[k].background).map(k => marketConfig[k].background)
  waitImageListLoaded(imgList)
}

/**
 * @description 获取直播间分享参数（分享标题为直播间标题）
 */
export function getClassRoomShareData () {
  const share = Store.getters['classroom/marketConfig'].share || {}
  return {
    ...COMMMON_SHARE_CONFIG,
    link: window.location.href,
    ...share
  }
}

/**
 * @description 解析直播埋点数据
 */
export function getLiveLogInfo (data) {
  return data.ext || {}
}
